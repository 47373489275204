import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'menu',
    'navigation',
    'option',
    'selectedLabel'
  ]

  static classes = ['isVisible', 'isSelected']

  get isVisible () {
    return this.hasMenuTarget && this.menuTarget.classList.contains(this.isVisibleClass)
  }

  get isHidden () {
    return !this.isVisible
  }

  connect () {
    if (this.hasSelectedLabelTarget) {
      let option = this.optionTargets.find((el) => el.className == this.isSelectedClass)
      option = option || this.optionTargets[0]

      if (option) {
        this.selectedLabelTarget.innerText = option.innerText
      }
    }
  }

  toggle (event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (this.isHidden) {
      this.show()
    } else {
      this.hide()
    }
  }

  show () {
    this.element.classList.add(this.isVisibleClass)

    if (this.hasMenuTarget) {
      this.menuTarget.classList.add(this.isVisibleClass)
    }
  }

  hide (event) {
    this.element.classList.remove(this.isVisibleClass)

    if (this.hasMenuTarget) {
      this.menuTarget.classList.remove(this.isVisibleClass)
    }
  }
}
